<template>
  <van-popup v-model="show" position="bottom" @close="onCancel">
    <van-picker
        show-toolbar
        :title="title"
        :columns="options"
        :value-key="valueKey"
        @cancel="onCancel"
        @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
export default {
  name: "PopupPicker",
  props: {
    title: {
      type: String,
      default: '选择框',
    },
    valueKey: {
      type: String,
      default: "test"
    },
    columns: {
      type: Array,
      default: () => []
    },
    keys: {
      type: String,
      default: 'id'
    },
    value: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      show: false,
      options: []
    }
  },
  methods: {
    onOpen() {
      this.show = true
    },
    onCancel() {
      this.show = false
    },
    onConfirm(event) {
      this.show = false
      console.log('event', event[this.keys])
      this.$emit('confirm', event[this.keys])
    }
  },
  watch: {
    columns (data) {
      this.options = JSON.parse(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>

</style>