<template>
  <div class="container">
    <div class="top_container">
      <a-icon @click="$router.go(-1)" class="back_btn" type="left"/>
      <span>报障报修</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="form_container">
        <a-form-model-item label="产品编号">
          {{ single.code }}
        </a-form-model-item>
        <a-form-model-item label="产品名称">
          {{ product.name }}
        </a-form-model-item>
        <a-form-model-item label="故障描述" prop="content">
          <a-textarea
              v-model="form.content"
              :auto-size="{ minRows: 4, maxRows: 4 }"
          />
        </a-form-model-item>
      </div>
      <div class="mt10 button-view">
        <div class="form-button" @click="selectLinkman" >
          选择已有联系人<van-icon style="color: #fff;" name="arrow" />
        </div>
      </div>
      <div class="form_container mt10">
        <a-form-model-item label="联系人" prop="linkman_name">
          <a-input v-model="form.linkman_name"/>
        </a-form-model-item>
        <a-form-model-item label="联系人电话" prop="linkman_mobile">
          <a-input v-model="form.linkman_mobile"/>
        </a-form-model-item>
      </div>
      <div class="mt10 button-view">
        <div class="form-button" @click="selectAddress" >
          选择已有地址<van-icon style="color: #fff;" name="arrow" />
        </div>
      </div>
      <div class="form_container mt10">
        <a-form-model-item label="地址" prop="area">
          <a-cascader :fieldNames="{label: 'name', value: 'code', children: 'children'}" :options="region"
                      placeholder="" v-model="form.area"/>
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="address">
          <a-input v-model="form.address"/>
        </a-form-model-item>
      </div>
      <div class="form_container mt10">
        <a-form-model-item label="附件" prop="attachment_list">
          <attachment-upload v-model="form.attachment_list"/>
        </a-form-model-item>
        <extend-field-form :field-list="fieldList" :form="form"></extend-field-form>
      </div>
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onSubmit">提交</a-button>
    </div>
    <popup-picker ref="linkman_picker" :columns="linkmanList" value-key="showName" @confirm="linkmanConfirm"></popup-picker>
    <popup-picker ref="address_picker" :columns="addressList" value-key="full_address" @confirm="addressConfirm"></popup-picker>
  </div>
</template>
<script>
import AttachmentUpload from '@/components/Attachment/upload'
import ExtendFieldForm from '@/components/ExtendField/form'
import PopupPicker from '@/components/PopupPicker'
import request from '@/utils/request';

export default {
  components: {ExtendFieldForm, AttachmentUpload, PopupPicker},
  data() {
    return {
      form: {},
      rules: {
        linkman_name: [{required: true, message: '请输入联系人'}],
        linkman_mobile: [
          {required: true, pattern: /^1[345789]\d{9}$/, message: '请输入正确的联系人电话'},
        ],
        content: [
          {required: true, message: '请输入故障描述'},
          {max: 255, message: '长度超出限制'}
        ],
        fault: [{
          type: 'array',
          required: true,
          message: '请故障类别',
          trigger: 'change'
        }]
      },
      region: [],
      faultList: [],
      fieldList: [],
      product: {},
      single: {},
      loading: false,
      linkmanList: [],
      addressList: []
    }
  },
  created: function () {
    this.form.customer_id = this.$route.query.customerId
    this.form.enterprise_id = this.$route.query.enterpriseId
    this.form.single_product_id = this.$route.query.singleProductId
    const qrId = this.$route.query.qrId
    request({
      url: 'single_product/detail_by_qr_id',
      data: {qr_id: qrId}
    }).then(r => {
      const { data } = r.data
      this.single = data.single
      this.product = data.product
      this.fieldList = data.fieldList
      this.linkmanList = data.linkman_list.map((item) => {
        item.showName = `${item.name}(${item.mobile})`
        return item
      })
      this.addressList = data.address_list
      const linkman = data.linkman_list.find(item => item.is_default === 1)
      if (linkman) {
        this.$set(this.form, 'linkman_name', linkman.name)
        this.$set(this.form, 'linkman_mobile', linkman.mobile)
      }
      const address = data.address_list.find(item => item.is_default === 1)
      if (address) {
        const area = []
        if (address.province_code) {
          area.push(address.province_code)
        }
        if (address.city_code) {
          area.push(address.city_code)
        }
        if (address.area_code) {
          area.push(address.area_code)
        }
        this.$set(this.form, 'area', area)
        this.$set(this.form, 'address', address.address)
      }
    })
    request({
      url: 'region/tree'
    }).then(r => {
      this.region = r.data.data
    })
    request({
      url: 'dropdown/category_cascade',
      data: {busi_type: 4, enterprise_id: this.form.enterprise_id}
    }).then(r => {
      this.faultList = r.data.data
    })
  },
  methods: {
    linkmanConfirm (value) {
      const linkman = this.linkmanList.find(item => item.id === value)
      if (linkman) {
        this.$set(this.form,'linkman_name', linkman.name)
        this.$set(this.form,'linkman_mobile', linkman.mobile)
      }
    },
    addressConfirm (value) {
      const address = this.addressList.find(item => item.id === value)
      if (address) {
        const area = []
        if (address.province_code) {
          area.push(address.province_code)
        }
        if (address.city_code) {
          area.push(address.city_code)
        }
        if (address.area_code) {
          area.push(address.area_code)
        }
        this.$set(this.form, 'area', area)
        this.$set(this.form, 'address', address.address)
      }
    },
    selectLinkman () {
      this.$refs.linkman_picker.onOpen()
    },
    selectAddress () {
      this.$refs.address_picker.onOpen()
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.attachment_list = JSON.stringify(this.form.attachment_list)
          if (data.area) {
            if (data.area[0]) {
              data.province_code = data.area[0]
            }
            if (data.area[1]) {
              data.city_code = data.area[1]
            }
            if (data.area[2]) {
              data.area_code = data.area[2]
            }
          }
          if (data.fault) {
            if (data.fault[0]) {
              data.big_fault_id = data.fault[0]
            }
            if (data.fault[1]) {
              data.small_fault_id = data.fault[1]
            }
          }
          this.loading = true
          request({
            url: 'feedback/customer_add',
            data: data
          }).then(r => {
            if (r.data.success) {
              alert('您的报修申请已经提交给客服人员，稍后会跟您联系，请保持电话畅通')
              this.$router.go(-1)
            } else {
              alert('网络异常,请稍后重试')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/css/form.less";

.ant-form-item {
  align-items: center;
}

/deep/ .ant-form-item-label {
  label::before {
    display: inline-block;
    margin-right: 11px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.container {
  padding: 8px;
  padding-top: 58px;

  .form_container {
    background-color: #fff;
    border-radius: 10px;
    .ant-form-item:last-child {
      border-bottom: none;
    }
  }

  .mt10 {
    margin-top: 10px;
  }

  .button-view {
    padding: 0 8px;
    display: flex;
    justify-content: flex-end;
  }

  .form-button {
    display: flex;
    align-items: center;
    margin-left: 5px;
    background: #3573e0;
    color: #fff;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 14px;
  }
}
</style>
